var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('div',{staticClass:"p-2 mt-3"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.register()}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-2"},[_c('i',{staticClass:"ri-user-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstname),expression:"user.firstname"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.firstname.$error,
                            },attrs:{"type":"text","id":"username","placeholder":"Enter first name"},domProps:{"value":(_vm.user.firstname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "firstname", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.firstname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" First name is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-2"},[_c('i',{staticClass:"ri-user-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.lastname),expression:"user.lastname"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.lastname.$error,
                            },attrs:{"type":"text","id":"username","placeholder":"Enter last name"},domProps:{"value":(_vm.user.lastname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "lastname", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.lastname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Last name is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-2"},[_c('i',{staticClass:"ri-user-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("User Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.username),expression:"user.username"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.username.$error,
                            },attrs:{"type":"text","id":"username","placeholder":"Enter user name"},domProps:{"value":(_vm.user.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "username", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" User name is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-2"},[_c('i',{staticClass:"ri-phone-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.phone),expression:"user.phone"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.phone.$error,
                            },attrs:{"type":"text","id":"username","placeholder":"Enter phone number"},domProps:{"value":(_vm.user.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "phone", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone number is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-2"},[_c('i',{staticClass:"ri-mail-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"useremail"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                            },attrs:{"type":"email","id":"useremail","placeholder":"Enter email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-2"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.password.$error,
                            },attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[(_vm.submitted == true)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Register ")])])])]),_c('div',{staticClass:"mt-2 text-center"},[_c('p',[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"font-weight-medium text-primary",attrs:{"tag":"a","to":"/login"}},[_vm._v("Login")])],1)])])])])])])]),_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../../../assets/xefe.png"),"height":"100","alt":"logo"}})])]),_c('h4',{staticClass:"font-size-18 mt-2"},[_vm._v("Register account")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Enter your details to register. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }